<section class="login__container">
    <section class="row">
        <section class="login__form">
            <div class="login__heading">
                <!-- <img src="../../assets/images/logo.png" alt=""  class="logo"  />  -->
                
                <!-- <mark style="color:red ;background:none">PowerERP</mark> -->
                
            </div>
            
            <div class="login__subheading mb-5"><i>A Complete Accounting Solution <br>
                <span>
                Version (3.0)
            </span>
            <!-- <br> -->
            <!-- <span style= "font-size: 10px;color :red">
             Dear Users, Company Code has been changed from 786 to 1107
            </span> -->
        </i>
        </div>
            

            <!-- <div class=""><i>Version 1</i></div>  -->
            <!-- <div class="col-md-12 text-center">
                <p class="sub-title">Please Log in with your Username</p>
            </div> -->
            

            <form class="col-md-12" [formGroup]="loginForm" (ngSubmit)="userLogin()">
                <div class=" mb-3 ; inputField">
                    <input type="text" class="form-control" formControlName="companyId" placeholder="Company ID"
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.companyId.errors }" > <!--(blur)="getCompany()"-->
                    <div *ngIf="loginCheck && f.companyId.errors" class="invalid-feedback">
                        <div *ngIf="f.companyId.errors.required">Company ID is required</div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="user" placeholder="Login ID"
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.user.errors }"> <!-- (blur)="getLoginId()"-->
                    <div *ngIf="loginCheck && f.user.errors" class="invalid-feedback">
                        <div *ngIf="f.user.errors.required">Login ID is required</div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input type="password" class="form-control" formControlName="password" placeholder="Password"
                        aria-label="Password" aria-describedby="Password"
                        [ngClass]="{ 'is-invalid': loginCheck && f.password.errors }">
                    <div *ngIf="loginCheck && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>

                <div class="text-right mb-3">
                    <button type="submit" class="btn btn-secondary btn-sm cursor-pointer button">Login</button>
                </div>

            </form>
            
            <!-- div class="font-weight-light font-italic text-center mt-5 pt-4">Best suited for Microsoft Edge and
        Firefox 54.x. Also available for the browser of your smartphone / tablet.</div -->
        </section>
       
        <!-- <span class="created-by">
        © 2020, Powered by A F Solutions
        </span> Test ERP With FA V 4.1.2 -->
      
    </section>
</section>

