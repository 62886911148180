import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment.prod';
import { Company } from '../models/company';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class CompanyService
{
  constructor(private http: HttpClient) { }
  private baseUrl = `${environment.apiUrl}/fams/company`;
  
  createCompany (company: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}` + `/create`, company, {observe: 'response'});
  }
  getCompany(){
    return this.http.get<Company[]>(`${this.baseUrl}/findAll`);
   }
   searchCompany(companyCode){
    return this.http.get<Company[]>(`${this.baseUrl}/companyCode/${companyCode}`);
  }
  updateCompany (company: Object): Observable<Object> {
    return this.http.put(`${this.baseUrl}` + `/update`, company, {observe: 'response'});
  }

  allCompany(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  updateLogoDetails(formData:Object):Observable<Object>{
    return this.http.post(`${this.baseUrl}/updateLogoDetails`,formData,{observe:'response'})
  }
  

  updateCompanyDetails(formData:Object):Observable<Object>{
    return this.http.post(`${this.baseUrl}/updateCompanyDetails`,formData,{observe:'response'})
  }

  fetchResolution(companyCode){
    return this.http.get<Company>(`${this.baseUrl}/fetchResolution/${companyCode}`,{observe:'response'})
  }

}